<template>
  <iframe :src="iframeSrc"></iframe>
</template>

<script>
export default {
  data() {
    return {
      iframeSrc: 'https://share.tcbi.qq.com/page/share?pageId=10537353&projectId=11025336&token=22b93a01-92ac-4080-8e6f-67c2b17841c6&scope=page&canvasType=GRID' // 替换为你想要嵌入的网站URL
    };
  }
};
</script>

<style>
/* 可以添加一些基本样式 */
iframe {
  width: 100%;
  height: 100%;
  border: none;
}
</style>